export const indianFormat = (x: string | number) => {
  if (x) {
    x = x?.toString();
    let afterPoint = "";
    if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length);
    x = Math.floor(Number(x));
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    const otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != "") lastThree = "," + lastThree;
    return `${otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",")}${lastThree}${
      afterPoint?.length ? afterPoint.slice(0, 3) : ""
    }`;
  } else {
    return "-";
  }
};

export const areaUnits = ["Sq.ft", "Acre"];
export const farmingProcess = [
  "Plowing",
  "Seeding",
  "Grow and maintain",
  "Harvesting",
  "Sell",
];

export const selectStyle = {
  input: (styles: any) => ({ ...styles, color: "white" }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "white",
  }),

  option: (styles: any, { isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isFocused
        ? "#008DDA"
        : isSelected
        ? "#8576FF"
        : undefined,
      border: "1px solid grey",
    };
  },
};

export const tableClass = {
  wrapper: ["border-none p-0 overflow-y-hidden"],
  th: ["text-sm"],
  td: [
    "group-data-[first=true]:first:before:rounded-none",
    "group-data-[first=true]:last:before:rounded-none",
    "group-data-[middle=true]:before:rounded-none",
    "group-data-[last=true]:first:before:rounded-none",
    "group-data-[last=true]:last:before:rounded-none",
  ],
};

export const colOptions = [
  "New Lead",
  "Contacted",
  "Qualified",
  "Proposal",
  "Closed",
];

export const leadOptions = [
  { value: "Hot", colour: "bg-red-500" },
  { value: "Warm", colour: "bg-orange-500" },
  { value: "Cold", colour: "bg-blue-500" },
  { value: "Success", colour: "bg-green-500" },
];
